@import "bootstrap/scss/bootstrap";

html {
  height: fill-available;
  height: -webkit-fill-available;
}

.viewer {
  max-width: 540px !important;
  padding: 0 !important;
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  min-height: fill-available;
  min-height: -webkit-fill-available;
  max-height: 100vh;
  max-height: fill-available;
  max-height: -webkit-fill-available;
}

.ar_view {
  position: relative;
  width: 100%;
  height: 100%;

  .text {
    position: absolute;
    width: 50%;
    bottom: 95px;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .take-image {
    position: absolute;
    width: 20%;
    bottom: 135px;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .image-view {
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
  }

  .close-image {
    width: 17%;
    top: 25%;
    left: 75%;
  }

  .c_left_up {
    position: absolute;
    top: 0;
    left: 0;
    width: 26%;
  }
  .c_right_up {
    position: absolute;
    top: 0;
    right: 0;
    width: 30%;
  }
  .c_left_down {
    position: absolute;
    bottom: 150px;
    left: 0;
    width: 26%;
  }
  .c_right_down {
    position: absolute;
    bottom: 150px;
    right: 0;
    width: 26%;
  }
  .score {
    position: absolute;
    width: 35%;
    top: 12%;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .score_text {
    position: absolute;
    width: 70%;
    top: 8%;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .save_text {
    top: 12%;
    color: white;
    font-size: 25px;
  }
}

.imageSet {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.wait_view {
  .background {
    height: 100vh
  }
  .background_start {
    height: 100vh;
  }
  .message_box {
    width: 80%;
  }
  .ricket {
    width: 75%;
    top: 87%;
    left: 31%;
  }
  .black_hole {
    width: 100%;
    top: 5%;
    left: 13%;
  }
  .earth {
    width: 100%;
    top: 96%;
    left: 64%;
  }
}

.question_view {
  .background {
    height: 100vh
  }
  .background_wave {
    position: absolute;
    width: 100%;
    bottom: 0;
  }
  .base {
    width: 100%;
    top: 75%;
  }
  .base_light {
    width: 100%;
    top: 68%;
  }
  .select_card {
    width: 65%;
  }
  .selected_card {
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;

    transition: top 1s linear;
    transition-delay: 0.5s;
  }
  .show_selected_card {
    top: 0;
  }


  .card01 {
    width: 30%;
    top: 18%;
    left: 25%;
    transform: translate(-50%, -50%) scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.1s linear;
    z-index: 0;
  }
  .card01:active {
    transform: translate(-50%, -50%) scale(1.2);
    z-index: 100;
  }
  .card02 {
    width: 30%;
    top: 18%;
    left: 75%;
    transform: translate(-50%, -50%) scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.1s linear;
    z-index: 0;
  }
  .card02:active {
    transform: translate(-50%, -50%) scale(1.2);
    z-index: 100;
  }
  .card03 {
    width: 30%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.1s linear;
    z-index: 0;
  }
  .card03:active {
    transform: translate(-50%, -50%) scale(1.2);
    z-index: 100;
  }
  .card04 {
    width: 30%;
    top: 82%;
    left: 25%;
    transform: translate(-50%, -50%) scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.1s linear;
    z-index: 0;
  }
  .card04:active {
    transform: translate(-50%, -50%) scale(1.2);
    z-index: 100;
  }
  .card05 {
    width: 30%;
    top: 82%;
    left: 75%;
    transform: translate(-50%, -50%) scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.1s linear;
    z-index: 0;
  }
  .card05:active {
    transform: translate(-50%, -50%) scale(1.2);
    z-index: 100;
  }

  .quest_area {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .quest_background{
    //position: absolute;
    width: 100%;
    //top: 50%;
    //left: 50%;
    //transform: translate(-50%, -50%);
  }
  .quest_text {
    position: absolute;
    width: 60%;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .quest_1 {
    width: 60%;
    top: 40%;
    left: 50%;
  }
  .quest_2 {
    width: 60%;
    top: 38%;
    left: 50%;
  }
  .quest_3 {
    width: 60%;
    top: 40%;
    left: 50%;
  }
  .quest_4 {
    width: 60%;
    top: 38%;
    left: 50%;
  }
  .quest_5 {
    width: 60%;
    top: 38.5%;
    left: 50%;
  }

  .quest_A {
    position: absolute;
    width: 27%;
    top: 71%;
    left: 35%;
    transform: translate(-50%, -50%);
  }
  .quest_B {
    position: absolute;
    width: 20%;
    top: 77.5%;
    left: 31.8%;
    transform: translate(-50%, -50%);
  }
  .quest_C {
    position: absolute;
    width: 21%;
    top: 84.5%;
    left: 32.3%;
    transform: translate(-50%, -50%);
  }

  .quest_A_ans{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .quest_B_ans{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .quest_C_ans{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .QA_1{
    width: 27%;
    top: 71%;
    left: 35%;
  }
  .QB_1{
    width: 20%;
    top: 77.5%;
    left: 31.8%;
  }
  .QC_1{
    width: 21%;
    top: 84.5%;
    left: 32.3%;
  }
  .QA_1_Ans {
    width: 35%;
    top: 71.5%;
    left: 67%;
  }
  .QB_1_Ans {
    width: 36%;
    top: 78%;
    left: 63%;
  }
  .QC_1_Ans {
    width: 40%;
    top: 85%;
    left: 64%;
  }

  .QA_2{
    width: 19%;
    top: 69%;
    left: 31.5%;
  }
  .QB_2{
    width: 19%;
    top: 76%;
    left: 31.8%;
  }
  .QC_2{
    width: 19%;
    top: 83%;
    left: 32%;
  }
  .QA_2_Ans {
    width: 42%;
    top: 69%;
    left: 64%;
  }
  .QB_2_Ans {
    width: 37%;
    top: 75.5%;
    left: 62%;
  }
  .QC_2_Ans {
    width: 38%;
    top: 83%;
    left: 63%;
  }

  .QA_3{
    width: 27%;
    top: 71%;
    left: 35%;
  }
  .QB_3{
    width: 31%;
    top: 78%;
    left: 37.1%;
  }
  .QC_3{
    width: 25%;
    top: 85%;
    left: 34.3%;
  }
  .QA_3_Ans {
    width: 35%;
    top: 71%;
    left: 67%;
  }
  .QB_3_Ans {
    width: 30%;
    top: 78%;
    left: 69%;
  }
  .QC_3_Ans {
    width: 36%;
    top: 85%;
    left: 66%;
  }

  .QA_4{
    width: 27%;
    top: 68%;
    left: 35%;
  }
  .QB_4{
    width: 21%;
    top: 75.5%;
    left: 32.1%;
  }
  .QC_4{
    width: 63%;
    top: 82.5%;
    left: 53.3%;
  }
  .QA_4_Ans {
    width: 35%;
    top: 68%;
    left: 67%;
  }
  .QB_4_Ans {
    width: 38%;
    top: 76%;
    left: 64%;
  }
  .QC_4_Ans {
    width: 36%;
    top: 86.5%;
    left: 63%;
  }

  .QA_5{
    width: 35%;
    top: 68%;
    left: 40%;
  }
  .QB_5{
    width: 35%;
    top: 75.5%;
    left: 40%;
  }
  .QC_5{
    width: 35%;
    top: 83.5%;
    left: 40%;
  }
  .QA_5_Ans {
    width: 40%;
    top: 71.5%;
    left: 59%;
  }
  .QB_5_Ans {
    width: 42%;
    top: 79%;
    left: 55.5%;
  }
  .QC_5_Ans {
    width: 40%;
    top: 87%;
    left: 54.5%;
  }

  .video_ans {
    width: 56%;
    position: absolute;
    top: 28.5%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .q2_ans {
    top: 26.6%;
  }

  .q4_ans{
    top: 28%;
  }
}

.rocket_view {
  background-color: black;
  .background {
    opacity: 0;
    height: 100vh;
  }
  .background_video {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .hint {
    width: 100%;
    position: absolute;
    top: 7%;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .loading_layout {
    width: 100%;
    position: absolute;
    bottom: 7%;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .loading {
    width: 95%;
    position: absolute;
    bottom: 8.2%;
    left: 50%;
    transform: translate(-50%, 0);
    clip: rect(0px, 0px, 100px,0px);
    //transition: clip 4s linear;
    //transition-delay: 0.5s;
  }

  .loading_set {
    //clip: rect(0px, 540px, 100px,0px);
    animation-name: launchLoading;
    animation-duration: 10s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
  }
  //27, 52 78, 103, 128, 153, 178, 203, 228, 253,278, 307, 332, 357, 382, 410, 435, 460, 485
  @keyframes launchLoading {
    0% {clip: rect(0px, 0px, 100px,0px);}           //0
    5% {clip: rect(0px, 27px, 100px,0px);}          //1
    10% {clip: rect(0px, 52px, 100px,0px);}         //2
    15% {clip: rect(0px, 78px, 100px,0px);}         //3
    20% {clip: rect(0px, 103px, 100px,0px);}        //4

    21% {clip: rect(0px, 78px, 100px,0px);}         //3
    22% {clip: rect(0px, 103px, 100px,0px);}        //4
    23% {clip: rect(0px, 78px, 100px,0px);}         //3
    24% {clip: rect(0px, 103px, 100px,0px);}        //4
    25% {clip: rect(0px, 78px, 100px,0px);}         //3
    26% {clip: rect(0px, 103px, 100px,0px);}        //4
    27% {clip: rect(0px, 78px, 100px,0px);}         //3
    29% {clip: rect(0px, 103px, 100px,0px);}        //4

    30% {clip: rect(0px, 128px, 100px,0px);}        //5
    31% {clip: rect(0px, 153px, 100px,0px);}        //6

    32% {clip: rect(0px, 128px, 100px,0px);}        //5
    34% {clip: rect(0px, 153px, 100px,0px);}        //6
    36% {clip: rect(0px, 128px, 100px,0px);}        //5
    37% {clip: rect(0px, 153px, 100px,0px);}        //6
    38% {clip: rect(0px, 128px, 100px,0px);}        //5
    40% {clip: rect(0px, 153px, 100px,0px);}        //6

    50% {clip: rect(0px, 178px, 100px,0px);}        //7
    52% {clip: rect(0px, 203px, 100px,0px);}        //8
    54% {clip: rect(0px, 228px, 100px,0px);}        //9
    57% {clip: rect(0px, 253px, 100px,0px);}        //10

    58% {clip: rect(0px, 228px, 100px,0px);}        //9
    60% {clip: rect(0px, 253px, 100px,0px);}        //10
    62% {clip: rect(0px, 228px, 100px,0px);}        //9

    64% {clip: rect(0px, 278px, 100px,0px);}        //11
    65% {clip: rect(0px, 307px, 100px,0px);}        //12
    66% {clip: rect(0px, 332px, 100px,0px);}        //13
    67% {clip: rect(0px, 357px, 100px,0px);}        //14
    68% {clip: rect(0px, 382px, 100px,0px);}        //15

    69% {clip: rect(0px, 357px, 100px,0px);}        //14
    70% {clip: rect(0px, 382px, 100px,0px);}        //15


    73% {clip: rect(0px, 410px, 100px,0px);}        //16
    76% {clip: rect(0px, 382px, 100px,0px);}        //15
    80% {clip: rect(0px, 410px, 100px,0px);}        //16
    84% {clip: rect(0px, 382px, 100px,0px);}        //15
    87% {clip: rect(0px, 410px, 100px,0px);}        //16


    90% {clip: rect(0px, 435px, 100px,0px);}        //17
    //93% {clip: rect(0px, 460px, 100px,0px);}        //18
    //97% {clip: rect(0px, 485px, 100px,0px);}        //19
    100% {clip: rect(0px, 510px, 100px,0px);}       //20
  }

  .keep_it_up {
    width: 50%;
    position: absolute;
    bottom: 3%;
    left: 50%;
    transform: translate(-50%, 0);
  }
}