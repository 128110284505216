@import "~bootstrap/scss/bootstrap";

.title {
  width: 1920px;
  max-width: 1920px;
  max-height: 1080px;
  overflow: hidden;

  .cire {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .title-words {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .title-G {
    position: absolute;
    top: 68%;
    left: 49%;
    transform: translate(-50%, -50%);
    transition: left 1s linear;
  }

  .title-8 {
    position: absolute;
    top: 57%;
    left: 68%;
    transform: translate(-50%, -50%) rotateZ(0deg);
    transform-origin: 45% 70%;
    transition: transform 1s linear;
  }

  .set {
    transform: translate(-50%, -50%) rotateZ(-90deg);
  }
  .set-G {
    left: 34.5%;
  }

  .plant01 {
    position: absolute;
    top: 3%;
    left: 96%;
    transform: translate(-50%, -50%);
  }

  .plant02 {
    position: absolute;
    top: 78.2%;
    left: 19.3%;
    transform: translate(-50%, -50%);
  }

  .plant03 {
    position: absolute;
    top: 5.5%;
    left: 30.5%;
    transform: translate(-50%, -50%);
  }

  .loading-background {
    position: absolute;
    top: 56%;
    left: 24%;
    transform: translate(-50%, -50%);
  }

  .loading-bar {
    position: absolute;
    top: 56%;
    left: 26.5%;
    transform: translate(-50%, -50%);
  }


}

.question {
  width: 1920px;
  max-width: 1920px;
  max-height: 1080px;
  overflow: hidden;

  .rocket_img {
    position: absolute;
    top: 0;
    right: 0;

  }

  .select_card {
    position: absolute;
    top: 58%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .card01 {
    left: 11% !important;
  }
  .card02 {
    left: 30.5% !important;
  }
  .card03 {
    left: 50% !important;
  }
  .card04 {
    left: 69.5% !important;
  }
  .card05 {
    left: 89% !important;
  }

  .base {
    position: absolute;
    top: 83%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .base_light {
    position: absolute;
    top: 76%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .selected_card {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .show_selected_card {
    position: absolute;
    top: 100%;
    left: 0;
    transition: top 1s linear;
    transition-delay: 0.5s;
  }
  .show_selected_card_show {
    top: 0;
  }
  .wave {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .question_background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .quest_img {
    position: absolute;
    top: 50%;
    left: 27%;
    transform: translate(-50%, -50%);
  }
  .quest_text {
    position: absolute;
    top: 34%;
    left: 70%;
    transform: translate(-50%, -50%);
  }
  .quest_a {
    position: absolute;
    top: 57%;
    left: 61%;
    transform: translate(-50%, -50%);
  }
  .quest_b {
    position: absolute;
    top: 67%;
    left: 59.5%;
    transform: translate(-50%, -50%);
  }
  .quest_c {
    position: absolute;
    top: 77%;
    left: 59.9%;
    transform: translate(-50%, -50%);
  }

  .QA_1 {
    left: 61%;
  }
  .QB_1 {
    left: 59.5%;
  }
  .QC_1 {
    left: 59.9%;
  }
  .Q1A_text {left: 78%;}
  .Q1B_text {left: 77%;}
  .Q1C_text {left: 77%;}

  .QA_2 {
    left: 61%;
  }
  .QB_2 {
    left: 61%;
  }
  .QC_2 {
    left: 61%;
  }
  .Q2A_text {left: 78%;}
  .Q2B_text {left: 78%;}
  .Q2C_text {left: 78%;}

  .QA_3 {
    left: 62%;
  }
  .QB_3 {
    left: 63%;
  }
  .QC_3 {
    left: 62%;
  }
  .Q3A_text {left: 80%;}
  .Q3B_text {left: 81.5%;}
  .Q3C_text {left: 80%;}

  .QA_4 {
    left: 61%;
  }
  .QB_4 {
    left: 59.9%;
  }
  .QC_4 {
    left: 70.9%;
  }
  .Q4A_text {left: 78%;}
  .Q4B_text {left: 77%;}
  .Q4C_text {
    left: 75.7%;
    top: 81.9%;
  }

  .QA_5 {
    left: 65%;
  }
  .QB_5 {
    left: 65%;
  }
  .QC_5 {
    left: 65%;
  }
  .Q5A_text {left: 85.3%;}
  .Q5B_text {left: 86.5%;}
  .Q5C_text {left: 87%;}

  .video_ans {
    width: 697px;
    height: 560px;
    position: absolute;
    top: 47.3%;
    left: 27%;
    transform: translate(-50%, -50%);
  }
}

.rocket {
  width: 1920px;
  max-width: 1920px;
  max-height: 1080px;
  overflow: hidden;
  position: relative;

  .hint {
    position: absolute;
    top: 50%;
    left: 15.7%;
    transform: translate(-50%, -50%);
  }

  .progress_background {
    position: absolute;
    top: 48%;
    left: 94%;
    transform: translate(-50%, -50%);
  }

  .progress_launch {
    position: absolute;
    top: 48%;
    left: 94%;
    transform: translate(-50%, -50%);
    clip: rect(872px, 95px, 872px, 0px);
    transition: clip 5s linear;
    transition-delay: 0.5s;
  }

  .progress_launched {
    clip: rect(0px, 95px, 872px, 0px);
  }

  .rocket_state1 {
    position: absolute;
    top: 73%;
    left: 86%;
    transform: translate(-50%, -50%);
  }

  .rocket_state2 {
    position: absolute;
    top: 44%;
    left: 82.1%;
    transform: translate(-50%, -50%);
  }

  .rocket_state3 {
    position: absolute;
    top: 21%;
    left: 82.4%;
    transform: translate(-50%, -50%);
  }

  .video_view {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .video_loop {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.animation-view {
  width: 1920px;
  max-width: 1920px;
  max-height: 1080px;
  overflow: hidden;
  position: relative;
}